//
// Styles spécifiques à la page des tarifs
// ---------------------------------------

.prices {
  .table {
    td {
      &:not(:first-child) {
        width: 7rem;
      }
    }
  }
}