//
// Styles spécifiques à la page des espaces
// ---------------------------------------

.areas {
  .card-img {
    height: 200px;
  }

  .card-body, .card-title {
    color: $white;
  }
}