//
// Styles spécifiques à la page de contact
// ---------------------------------------

#gmap {
  position: relative;
  height: 20rem;
  margin-top: -$main-padding-top-sup;
}

.contact-info {
  background-color: rgba(0, 60, 127, 0.8);
  color: $white;
  padding: 1rem;

  dt, dd {
    padding: 1rem;

    a {
      color: $white;

      @include hover-focus {
        color: $white;
      }
    }
  }
}

.etd-icon {
  background-color: #508ccf;
  color: $white;
}