/**
 * @package      Template Joomla pour le site web de l'Aa Piscine
 *
 * @version      0.1.0
 * @copyright    Copyright (C) 2018 ETD Solutions. All rights reserved.
 * @license      Apache-2.0
 * @author       ETD Solutions http://www.etd-solutions.com
**/

//
// App
// ---------------------------------------

// Twitter Bootstrap
// --------------------------
@import "bootstrap";

// Fonts
// --------------------------
@import "fonts";

// Variables
// --------------------------
@import "variables";

// Utils
// --------------------------
@import "utils";

// Theme Bootstrap
// --------------------------
@import "bootstrap-theme";

// Error
// --------------------------
@import "error";

// Offline
// --------------------------
@import "offline";

// Base
// --------------------------
@import "base";

// Modules
// --------------------------
@import "modules/custom";
@import "modules/menu";

// Views
// --------------------------
@import "views/home";
@import "views/areas";
@import "views/hours";
@import "views/prices";
@import "views/clubs";
@import "views/contact";