//
// Styles spécifiques aux utilitaires
// ---------------------------------------

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 4px solid lighten($dark, 50%);
  border-top: 4px solid $dark;
  border-radius: 50%;
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bg-img {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-attachment: local;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

img:not([src]) {
  visibility: hidden;
}

@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden;
  }
}

.lazy {
  opacity: 0;
  transition: opacity 0.5s ease-in-out 0.5s;

  &.loaded {
    opacity: 1;
  }
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

@keyframes shake {
  3%, 9%, 15%, 21% {
    transform: translate3d(-10px, 0, 0);
  }

  6%, 12%, 18% {
    transform: translate3d(10px, 0, 0);
  }

  24%, 100% {
    transform: translate3d(0, 0, 0);
  }
}