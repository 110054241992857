//
// Styles spécifiques à la page des calendriers
// ---------------------------------------

.periods {
  td:first-child {
    font-family: $headings-font-family;
  }
}

.hours-config-color {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.activityhours-wrppr {
  overflow: scroll;
}

.activityhours {
  min-width: 980px // 140px (taille minimum pour le texte) * 7 (jours par semaine)
}

.acthrs-day {
  width: 14.28571%;
  border: 1px solid #dee2e6;
  border-right: none;

  &:last-child {
    border-right: 1px solid #dee2e6;
  }
}

.acthrs-hdr {
  .acthrs-day {
    background-color: $dark;
    color: $white;
    font-family: $headings-font-family;
    padding: 1rem;
    text-align: center;
    border-bottom: none;
  }
}

.acthrs-act {
  padding: 0.5rem;
  border-bottom: 1px solid #dee2e6;

  &:last-child {
    border: none;
  }
}

.acthrs-hr {
  font-size: 0.875rem;
  color: $dark;
}