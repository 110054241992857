//
// Styles spécifiques à la page d'accueil
// ---------------------------------------

$area-card-height: 18.75rem; // 18.75rem = 300px if 1rem = 16px
$area-card-transition: all 0.5s ease-in-out;

.mod-areas {
  .card-img {
    height: $area-card-height;
  }

  .card-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(72, 159, 216, 0.3);
    transition: $area-card-transition;
    padding: 1rem;
  }

  .card-text {
    opacity: 0;
    color: $white;
    text-align: center;
    transition: $area-card-transition;
    max-height: 0;
    overflow: hidden;
  }

  .card-title {
    z-index: 1;
    color: white;
    font-weight: 600;
    text-shadow: 0 0 10px #000;
    text-align: center;
  }

  .card {
    @include hover-focus {
      cursor: pointer;

      .card-body {
        background-color: rgba(245, 166, 35, 0.75);
      }

      .card-text {
        opacity: 1;
        max-height: 100%;
      }
    }
  }
}