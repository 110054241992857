//
// Styles spécifiques au module de menu
// ---------------------------------------

#navbar-nav {
  margin-bottom: -2px;

  .active {
    .nav-link {
      border-bottom: 2px solid $secondary;

      @include media-breakpoint-down(md) {
        border: none;
        background-color: $primary;
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    padding-top: 1.75rem;
    width: 285px;
    left: -285px;
    height: 100%;
    overflow: auto;
    background-color: $dark;
    z-index: 1090;
    transition: left 0.5s ease;

    .nav-link {
      color: $gray-200;
      padding-right: 1rem;
      padding-left: 1rem;

      @include hover-focus {
        color: $white;
      }
    }
  }
}

#navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  overflow: visible;
  fill: $primary;

  path {
    transition: all 0.4s ease;
  }

  &.active {
    path:first-child {
      transform: translateX(7px);
    }

    path:last-child {
      transform: translateX(-7px);
    }
  }
}

.navbar-toggler {
  border: none;

  @include hover-focus {
    outline: 0;
  }
}

body {
  @include media-breakpoint-down(md) {
    &.open {
      overflow: hidden;

      #navbar-nav {
        left: 0;
      }
    }
  }
}

//
// Navbar icons on the homepage
//
.nav-icons {
  flex-direction: initial;
  position: absolute;
  bottom: -3rem;
  left: 0;
  right: 0;
  z-index: $zindex-sticky;

  @include media-breakpoint-down(xs) {
    bottom: -2rem;
  }

  .nav-item {
    display: inline-block;
    width: 6rem;
    height: 6rem;
    background: $secondary;
    border-radius: 50%;
    margin: 0 1rem;

    @include media-breakpoint-down(xs) {
      width: 4rem;
      height: 4rem;
    }
  }

  .nav-link {
    color: $gray-200;
    padding:0;
    text-align: center;

    > svg {
      font-size: 2rem;
      height: 3rem;

      @include media-breakpoint-down(xs) {
        height: 4rem;
      }
    }

    > div {
      line-height: 1;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    @include hover-focus {
      color: $white;
    }
  }
}