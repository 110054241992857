//
// Styles spécifiques au module de custom
// ---------------------------------------

.exceptional-opening {
  background: $primary;
  color: $white;
  position: absolute;
  left: 0;
  bottom: $page-heading-height / 10;
  padding: $exceptional-opening-padding-y $exceptional-opening-padding-x $exceptional-opening-padding-y $exceptional-opening-padding-x * 2;
  margin-left: -$exceptional-opening-padding-x;
  border-top-right-radius: $font-size-base * $line-height-base + $exceptional-opening-padding-y;
  border-bottom-right-radius: $font-size-base * $line-height-base + $exceptional-opening-padding-y;
  transform: translate3d(0, 0, 0);
  animation: transform 5s linear 2s infinite;
  animation-name: shake;
}