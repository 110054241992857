//
// Styles de base
// ---------------------------------------

// Antialiasing
// ---------------------------------------
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1, h2, .h2 {
  margin-top: $headings-margin-bottom;

  &::after {
    content: " ";
    display: block;
    width: 3.125rem; // =50px if 1rem = 16px
    height: 0.1875rem;
    margin-top: 1rem;
    background-color: $java;
  }
}

#logo {
  max-height: $logo-height;
}

// Header de chaque page (contenant l'image de fond)
#hero {
  height: $page-heading-height;
  position: relative;
  padding-top: $navbar-padding-y * 2 + $navbar-brand-padding-y * 2 + $logo-height;

  .bg-img {
    z-index: -1;
  }

  @include media-breakpoint-down(sm) {
    min-height: 400px;
    height: auto;
  }
}

main {
  background-color: $white;
  padding-top: $navbar-padding-y * 2 + $navbar-brand-padding-y * 2 + $logo-height + $main-padding-top-sup;

  &.hero {
    padding-top: 2rem;
  }
}

// Blog
// ---------------------------------------
.blog-featured {
  margin-top: -$headings-margin-bottom;

  .item {
    position: relative;
    padding: 3rem 0;
    color: $white;

    > .bg-img {
      filter: blur(5px);
    }

    > .container {
      position: inherit;
    }

    .item-title {
      color: $white;
      text-align: center;
      text-transform: uppercase;

      &::after {
        display: none;
      }
    }
  }
}

.blog-item-body {
  background-color: #eeeeee;
}

.blog-item-img {
  padding-right: 0;
  padding-left: 0;
  z-index: 1;

  img {
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.2);
  }
}

// Waves
// ---------------------------------------
.wave {
  &::before, &::after {
    content: " ";
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 10%;
  }
}

.wave-1 {
  margin-top: -5%;

  .wave-wrppr, &::after {
    background-color: #eef7ff;
  }

  &::before {
    background-image: url('../img/wave-1.png');
  }
}

.wave-2 {
  margin-top: -10%;

  .wave-wrppr, &::after {
    background-color: #deefff;
  }

  &::before {
    background-image: url('../img/wave-2.png');
  }
}

.wave-3 {
  margin-top: -10%;

  .wave-wrppr, &::after {
    background-color: #cee7ff;
  }

  &::before {
    background-image: url('../img/wave-3.png');
  }
}

// Icons
// ---------------------------------------
.etd-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}

footer {
  &, a, h3, .h3 {
    color: $gray-200;

    @include hover-focus {
      color: $white;
    }
  }

  h3, .h3 {
    &::after {
      content: " ";
      display: block;
      width: 3.125rem;
      height: 0.1875rem;
      margin-top: 1rem;
      background-color: #fff;
    }
  }

  .nav-child {
    list-style-type: none;
    padding: 0;

    .nav-link {
      padding: 0;
    }
  }
}

#footer {
  background-color: #353950;
}

#etdsolutions {
  display: inline-block;
  height: 2.5rem; // =40px if 1rem = 16px
  padding: 0.5rem 3rem 0.5rem 0;
  background-image: url('../img/etd-solutions.png');
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: contain;
}