//
//
// Surchage des styles Bootstrap
// ---------------------------------------

//
// Navbar
//

#navbar {
  &.navbar-light {
    background-color: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.navbar {
  .nav-link {
    font-family: $headings-font-family;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

//
// Table
//

.table {
  th {
    font-weight: $font-weight-base;
    font-family: $headings-font-family;
  }

  td, th {
    &:not(:first-child) {
      text-align: center;
    }
  }
}

//
// Buttons
//

.btn-light {
  background-color: $white;
  border-color: $primary;
  color: $primary;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: -115%;
    z-index: -1;
    content: "";
    display: block;
    width: 110%;
    height: 2.375rem;
    background: $primary;
    transform: skewX(-10deg);
    transition: left .2s ease-in-out;
  }

  &.active, &:hover, &focus {
    background-color: transparent !important;
    border-color: $primary !important;
    color: $white !important;

    &::after {
      left: -5%;
    }
  }
}

//
// Cards
//
.card-title {
  word-wrap: initial;
}

.card-img {
  position: relative;
}

.card-footer {
  background-color: transparent;
  padding-top: 0;
  text-align: center;
}

//
// Carousel avec 4 slides visibles
//

.multislides-carousel {
  width: 100%;
  padding: 1rem;

  @include media-breakpoint-down(xs) {
    padding: 2rem;
  }

  .carousel-item {
    margin: 0;
  }

  .card-img {
    position: relative;
    height: 15rem;
  }

  .card-header {
    padding: 1rem;
    background-color: $secondary;
  }

  .carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: flex;
    align-items: unset;
  }

  .carousel-item-next, .active.carousel-item-right {
    transform: translateX(25%);

    @supports (transform-style: preserve-3d) {
      transform: translate3d(25%, 0, 0);
    }

    @include media-breakpoint-down(md) {
      transform: translateX(50%);

      @supports (transform-style: preserve-3d) {
        transform: translate3d(50%, 0, 0);
      }
    }

    @include media-breakpoint-down(xs) {
      transform: translateX(100%);

      @supports (transform-style: preserve-3d) {
        transform: translate3d(100%, 0, 0);
      }
    }
  }

  .carousel-item-prev, .active.carousel-item-left {
    transform: translateX(-25%);

    @supports (transform-style: preserve-3d) {
      transform: translate3d(-25%, 0, 0);
    }

    @include media-breakpoint-down(md) {
      transform: translateX(-50%);

      @supports (transform-style: preserve-3d) {
        transform: translate3d(-50%, 0, 0);
      }
    }

    @include media-breakpoint-down(xs) {
      transform: translateX(-100%);

      @supports (transform-style: preserve-3d) {
        transform: translate3d(-100%, 0, 0);
      }
    }
  }

  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateX(0);

    @supports (transform-style: preserve-3d) {
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-control-prev, .carousel-control-next {
    width: 1rem;
    color: $dark;

    @include media-breakpoint-down(xs) {
      width: 2rem;
    }
  }

  @include media-breakpoint-down(md) {
    .card {
      &:nth-child(3), &:nth-child(4) {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .card {

      &:nth-child(2) {
        display: none;
      }
    }
  }
}