//
// Styles spécifiques aux polices
// ---------------------------------------

@font-face {
  font-family: 'Phenomena';
  src: url('../fonts/Phenomena-Thin.eot');
  src: url('../fonts/Phenomena-Thin.ttf') format('truetype'),
  url('../fonts/Phenomena-Thin.woff') format('woff'),
  url('../fonts/Phenomena-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Phenomena-Thin.svg#Phenomena-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Phenomena';
  src: url('../fonts/Phenomena-ExtraLight.eot');
  src: url('../fonts/Phenomena-ExtraLight.ttf') format('truetype'),
  url('../fonts/Phenomena-ExtraLight.woff') format('woff'),
  url('../fonts/Phenomena-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Phenomena-ExtraLight.svg#Phenomena-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Phenomena';
  src: url('../fonts/Phenomena-Light.eot');
  src: url('../fonts/Phenomena-Light.ttf') format('truetype'),
  url('../fonts/Phenomena-Light.woff') format('woff'),
  url('../fonts/Phenomena-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Phenomena-Light.svg#Phenomena-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Phenomena';
  src: url('../fonts/Phenomena-Regular.eot');
  src: url('../fonts/Phenomena-Regular.ttf') format('truetype'),
  url('../fonts/Phenomena-Regular.woff') format('woff'),
  url('../fonts/Phenomena-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Phenomena-Regular.svg#Phenomena-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Phenomena';
  src: url('../fonts/Phenomena-Bold.eot');
  src: url('../fonts/Phenomena-Bold.ttf') format('truetype'),
  url('../fonts/Phenomena-Bold.woff') format('woff'),
  url('../fonts/Phenomena-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Phenomena-Bold.svg#Phenomena-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Phenomena';
  src: url('../fonts/Phenomena-ExtraBold.eot');
  src: url('../fonts/Phenomena-ExtraBold.ttf') format('truetype'),
  url('../fonts/Phenomena-ExtraBold.woff') format('woff'),
  url('../fonts/Phenomena-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Phenomena-ExtraBold.svg#Phenomena-ExtraBold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Phenomena';
  src: url('../fonts/Phenomena-Black.eot');
  src: url('../fonts/Phenomena-Black.ttf') format('truetype'),
  url('../fonts/Phenomena-Black.woff') format('woff'),
  url('../fonts/Phenomena-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Phenomena-Black.svg#Phenomena-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}