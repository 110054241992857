//
// Styles spécifiques à la page des clubs
// ---------------------------------------

.club-logo {
  width: 10rem;
  display: flex;
  margin: 2rem auto;
}

.blog-item {
  .club-links {
    border: 1px solid $java;
    background-color: $white;
    color: $java;
    margin: 1rem 1rem 1rem 0;

    @include hover-focus {
      background-color: $java;
      color: $white;
    }
  }
}